<template>
  <div class="logistics">
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
    <!-- 头部 -->
    <van-nav-bar
      title="物流信息"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="99"
    />
    <!-- 内容部分 -->
    <section>
      <!-- <div class="logistics-info">
        <ul>
          <li>{{transStatusTxt}}</li>
          <li>{{transTotalInfo.logisticsCorp}}&nbsp;&nbsp;{{transTotalInfo.logistics}}</li>
        </ul>
        <p>
          <span class="iconfont icon-kefu" @click="goCustomerServer"></span>客服
        </p>
      </div>-->
      <div class="logistics-list">
        <van-steps direction="vertical" :active="active">
          <van-step>
            <span
              class="iconfont icon-shouhuoshou--"
              slot="inactive-icon"
              style="color: rgb(7, 193, 96); font-size: 0.4rem"
            ></span>
            <p>[ 收货地址 ]{{ orderAddress }}</p>
          </van-step>
          <van-step v-for="item in transList.list" :key="item.id">
            <!-- <p>{{item.APLLName}}</p> -->
            <h3
              style="
                font-size: 12px;
                font-weight: normal;
                font-family: '微软雅黑';
              "
            >
              {{ item.status }}
            </h3>
            <p style="font-size: 12px; font-weight: normal">{{ item.time }}</p>
          </van-step>
        </van-steps>
      </div>
    </section>
  </div>
</template>

<script>
import { NavBar, Step, Steps } from "vant";
import { appendFile } from "fs";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  created() {
    this.getLogistics();
    // this.$nextTick(() => {
    //   // this.size = document.documentElement.style.fontSize;
    //   this.size = window.getComputedStyle(document.documentElement)["fontSize"];
    //   console.log(this.size);
    //   let showsize = this.size.split("p")[0];
    //   console.log(showsize);

    //   document.documentElement.style.fontSize = showsize / 3.125 + "px";
    // });

    // this.initOrderList(0);
    let active = this.$route.query.active || "0";
    this.active = active;
  },
  // beforeDestroy() {
  //   document.documentElement.style.fontSize = this.size;
  // },
  data() {
    return {
      orderAddress: sessionStorage.getItem("orderAddress"),
      transList: [], // 物流信息列表
      active: 1,
      isLoading: true,
      transTotalInfo: {},
    };
  },

  methods: {
    onClickLeft() {
      // this.$router.back(0);
      this.$router.replace({ name: "logisticsDetails", params: { orderNo:this.$route.params.orderNo } });

    },
    getLogistics() {
      let item = this.$route.params.item;
      this.$api.my
        .translist({
          shipNo: item.shipNo,
          shipCode: item.shipCode,
          // shipNo: '75870303262054',
          // shipCode: 'ZTO',
        })
        .then((res) => {
          if (res.errno === 200) {
            let { transStatus, logistics, address, logisticsCorp } =
              res.data.result;
            this.transTotalInfo.transStatus = res.data.result.deliverystatus;
            // this.transTotalInfo.logistics = logistics;
            // this.transTotalInfo.address = address;
            // this.transTotalInfo.logisticsCorp = logisticsCorp;
            this.transList = res.data.result;
            this.isLoading = false;
          }
        });
    },
    goCustomerServer() {
      this.$router.push("/customerserver");
    },
  },
  computed: {
    transStatusTxt() {
      switch (this.transTotalInfo.transStatus) {
        case "0":
          return "未配送";
        case "1":
          return "配送中";

        case "2":
          return "已收货";

        case "3":
          return "配送异常";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.logistics {
  width: 100%;
  box-sizing: border-box;
  padding: 0.96rem 0 0.64rem;

  .van-nav-bar {
    color: #373737;
    letter-spacing: 1px;
    .van-icon {
      color: #373737;
    }
  }
  & > section {
    padding: 0 0.18rem;
    & > div {
      margin: 0.24rem 0 0;
      &.logistics-info {
        display: flex;
        padding: 0.24rem;
        background-color: #fff;
        border-radius: 0.12rem;
        font-size: 0.25rem;
        align-items: center;
        justify-content: space-between;
        ul {
          li {
            line-height: 0.44rem;
            &:first-child {
              font-size: 0.35rem;
            }
          }
        }
        & > p {
          color: #de7a63;
          font-size: 0.24rem;
          letter-spacing: 1px;
          span {
            display: block;
            font-size: 0.53rem;
          }
        }
      }
      &.logistics-list {
        margin: 0.12rem 0 0;
        .van-steps {
          border-radius: 0.12rem;
          .van-step {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>